<template>
  <section class="about" style="background: #FFF;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner-s"
      >
        <v-layout
          class="hidden-sm-and-down"
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
      <v-container  grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="tnf-top-div">
            <h2>Development</h2>
            <h4>发展历程</h4>
            <h5> </h5>
            <!--移动端-->
            <v-layout wrap class="hidden-lg-only hidden-md-only hidden-xl-only">
              <v-flex xs12 sm12 md12 lg12>
                <template>
                  <v-timeline align-top dense>
                    <v-timeline-item v-for="(tl, mi) in timeline" :key="mi" small color="teal lighten-3" mt-4>
                      <div>
                        <h2 class="headline font-weight-light mb-1">
                          {{mi}}
                        </h2>
                        <div>
                          <ul class="m-ul">
                            <li v-for="(t, tmi) in tl" :key="tmi">{{t.desc}}</li>
                          </ul>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </v-flex>
            </v-layout>
            <!--PC-->
            <v-layout wrap class="hidden-sm-and-down">
              <v-flex xs12 sm12 md12 lg12 style="background: url(https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/history_bg.jpg);background-size:cover; background-repeat:no-repeat;">
                <template>
                  <v-tabs
                    class="swiper-slider"
                    background-color="light"
                    center-active
                    centered
                    light
                    hide-slider
                    v-model="activeDate"
                  >
                    <v-tab v-for="(tl, t) in timeline" :key="t">
                      <div class="swiper-slide">
                        <div class="timestamp">
                          <span class="date">{{t}}</span>
                        </div>
                        <div class="status">
                          <span><b>{{tl[0].desc}}</b></span>
                        </div>
                      </div>
                    </v-tab>
                  </v-tabs>
                </template>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 mt-6>
                <v-layout wrap>
                  <v-flex xs12 sm12 md4 lg4 xl4  v-for="(sd, k, i) in showData" :key="i">
                    <div class="card-time">{{k}}</div>
                    <ul class="card-content">
                      <li v-for="(t, ti) in sd" :key="ti">{{t.desc}}</li>
                    </ul>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <h1 class="tnf-large-text">DEVELOPMENT</h1>
          </v-flex>
        </v-layout>
      </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    tabSubMenu: null,
    subMenu: [
      { key: 'about', name: '公司简介' },
      { key: 'story', name: '品牌故事' },
      { key: 'history', name: '发展历程' },
      { key: 'honor', name: '企业荣誉' },
      { key: 'assistant', name: '门窗管家' },
      { key: 'partner', name: '合作伙伴' }
    ],
    activeDate: null,
    showData: {},
    dateMap: [],
    timeline: []
  }),
  created () {
    this.getAbout()
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    activeDate: {
      handler: function (val, oldVal) {
        this.showData = {}
        if (val - 2 >= 0) {
          this.showData[this.dateMap[val - 2]] = this.timeline[this.dateMap[val - 2]]
        }
        if (val - 1 >= 0) {
          this.showData[this.dateMap[val - 1]] = this.timeline[this.dateMap[val - 1]]
        }
        this.showData[this.dateMap[val]] = this.timeline[this.dateMap[val]]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    delHtmlTag (str) {
      return str.replace(/<[^>]+>/g, '')
    },
    getAbout () {
      let that = this
      this.https.get('about').then(response => {
        if (response.code === 0) {
          let rs = response.data.timeline
          rs.reverse()
          let timeline = {}
          rs.forEach(function (e) {
            let ymd = ' ' + e.ymd.substring(0, 4) + ' '
            e.desc = that.delHtmlTag(e.desc)
            if (timeline[ymd]) {
              timeline[ymd].push(e)
            } else {
              timeline[ymd] = [e]
              that.dateMap.push(ymd)
            }
          })
          this.timeline = timeline
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/history.css';
</style>
